<template>
  <div class="container">
    <div class="newsList flex-box">
      <div class="newItem" v-for="(item, index) in newsList" :key="index" @click="clickToDetail(index)">
        <img class="pic" :src="item.pic" />
        <div class="news-info">
          <div class="title" v-html="language === 'cn' ? item.cnTitle : item.enTitle"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeImageUrl } from '@/utils'
import { jsonRequest } from '../../utils/request'
import { PathEnum } from '../../enum/pathEnum'
export default {
  name: 'news',
  components: {},
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  data() {
    return {
      newsList: []
    }
  },
  created() {
    this.getNewsList()
  },
  methods: {
    async getNewsList() {
      const { data } = await jsonRequest(PathEnum.NEWS)
      this.newsList = data.list.map((item) => {
        return {
          ...item,
          pic: changeImageUrl(item.banner)
        }
      })
    },
    clickToDetail(index) {
      this.$router.push({ name: 'about|detail', params: { id: index + 1 } })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
}
.newsList {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  .newItem {
    width: calc(50% - 6px);
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 11px;
    .pic {
      width: 100%;
      height: 540px;
    }
    .news-info {
      padding: 14px 24px 32px 24px;
      height: 106px;
      background: #ffffff;
    }
    .title {
      color: #333;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0;
    }
    .news-desc {
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
      color: #666;
    }
  }
}

// @media screen and(max-width: 1450px) {
//   .newsList .newItem .title {
//     font-size: 26px;
//   }
// }
// @media screen and(max-width: 1350px) {
//   .newsList .newItem .title {
//     font-size: 24px;
//   }
// }

// @media screen and(max-width: 1240px) {
//   .newsList .newItem .title {
//     font-size: 22px;
//   }
// }

@media screen and (max-width: 1140px) {
  .newsList .newItem .title {
    font-size: 18px;
  }
}
</style>
